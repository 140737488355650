import { AnyAction } from 'redux';
import { Channel } from '../../core/entities/Channel';
import { PlaybackState } from '../videoplayerui/infobanner/PlaybackTypes';
import {
    VIDEO_PLAYER_START_PLAYBACK,
    VIDEO_PLAYER_RESTART_PLAYBACK,
    VIDEO_PLAYER_RESET_RETRY,
    VIDEO_PLAYER_TOGGLE_IS_PLAYING,
    VIDEO_PLAYER_SET_PLAYBACK_STATE,
    UPDATE_STILL_THERE_TIMER,
    SET_EXIT_PLAYER_TIMER,

    SHOW_STILL_THERE_OSD,
    HIDE_STILL_THERE_OSD,
    VIDEO_PLAYER_IS_PAUSE_BUTTON_PRESSED,
    IS_STILL_THERE,
} from './videoplayer.types';

export const videoPlayerStartPlaybackAction = (channel: Channel): AnyAction => ({
    type: VIDEO_PLAYER_START_PLAYBACK,
    payload: {
        channel,
    },
});

export const videoPlayerRestartPlaybackAction = (channel: Channel, retry: number): AnyAction => ({
    type: VIDEO_PLAYER_RESTART_PLAYBACK,
    payload: {
        channel,
        retry,
    },
});

export const videoPlayerResetRetryAction = (): AnyAction => ({
    type: VIDEO_PLAYER_RESET_RETRY,
});

export const videoPlayerToggleIsPlayingAction = (isPlaying: boolean): AnyAction => ({
    type: VIDEO_PLAYER_TOGGLE_IS_PLAYING,
    payload: {
        isPlaying,
    },
});

export const videoPlayerSetPlaybackStateAction = (playbackState: PlaybackState): AnyAction => ({
    type: VIDEO_PLAYER_SET_PLAYBACK_STATE,
    payload: {
        playbackState,
    },
});

export const videoPlayerIsMediaButtonPressedAction = ({
    type,
    isMediaButtonPressed,
}): AnyAction => ({
    type: VIDEO_PLAYER_IS_PAUSE_BUTTON_PRESSED,
    payload: {
        mediaButtonPressed: {
            type,
            isMediaButtonPressed,
        },
    },
});

export const showStillThereOsdAction = (): AnyAction => ({
    type: SHOW_STILL_THERE_OSD,
    payload: { isStillThereOsdActive: true },
});

export const hideStillThereOsdAction = (): AnyAction => ({
    type: HIDE_STILL_THERE_OSD,
    payload: { isStillThereOsdActive: false },
});

export const resetStillThereTimeoutAction = (stillThereTimer: any): AnyAction => ({
    type: UPDATE_STILL_THERE_TIMER,
    payload: {
        stillThereTimer,
    },
});

export const isStillThereAction = (isStillThere: boolean): AnyAction => ({
    type: IS_STILL_THERE,
    payload: {
        isStillThere,
    },
});

export const setExitPlayerTimeoutAction = (exitPlayerTimer: any): AnyAction => ({
    type: SET_EXIT_PLAYER_TIMER,
    payload: {
        exitPlayerTimer,
    },
});
